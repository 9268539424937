.help-line {

    .header {

        .bg-success {
            background-color: $yellow !important;

            .container {
                padding-left: 10px !important;
                padding-right: 10px !important;
            }
            
        }

        

    }

    .container {
        width: 1140px;
    }

    .text-info {
        color: #2185D2 !important
    }

    .zero-down {

        .header {

            h1, h2{
                background: black;
                color: $yellow
            }

            h1 {
                font-size: 4rem;
                padding: 20px 30px;
                line-height: 80px;

            }

            h2 {
                font-size: 2rem;
                padding: 20px 30px;
                line-height: 40px;
            }

            p {
                color: $black;
            }

            .card {
                margin-bottom: -500px;
                padding: 15px 30px
            }

            @media(max-width: 1100px) {

                h1 {
                    font-size: 3.5rem;;
                    line-height: 70px;
                }

                h2 {
                    font-size: 1.6rem;;
                    line-height: 30px;
                }


            }


        }

        .btn-warning {
            background: $yellow;
            border: none;
            color: black;
        }


    }

    .letter {

        .bg-success {
            background: $yellow !important;
        }

        .text-info {
            color: black !important;
        }

    }

    @media(max-width: 1000px) {

        .under-hero {
            padding-top: 500px !important
        }

        .card {
            margin-top: 20px
        }

        .icons {

            .text-left, .text-right, .text-center {
                text-align: left !important
            }

        }

    }

     @media(max-width: 575px) {

        .header {
            .container {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;

                h1 {
                    font-size: 2.5rem;
                    line-height: 50px
                }
            }
        }

    }


}
