
.navbar:not(.navbar-nav-hover) .dropdown-menu.show {

    background: white;
    border-radius: 0;

}

.dropdown-menu-dark {
    .h1, .h2, .h3, .h4, .h5, .h6, a {
        color: $body-color;
    }
}

.select2-results__option {
    background: $primary;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    color: $white;
}

.select2-container--default li:hover {
    color: $primary !important;
}
