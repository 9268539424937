.noScroll {
    /* ...or body.dialogShowing */
    overflow: hidden !important;
}

.cursor-pointer {
    cursor: pointer;
}

.progress-gradient.progress {

    &.finished {
        .progress-bar {
            background: $green !important
        }
    }

    .progress-bar {
        background: linear-gradient(to right, $red 5%, $orange 20%, $green 60%) !important;
    }

}

.background.full-background {
    overflow: scroll;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    background: $body-bg;
    padding: $spacer * 3;
}

.list-style-none {
    list-style: none;
}

ul.hover-click {
    li {
        &:hover {
            background: $gray-200;
            cursor: pointer;
        }
    }
}

.dz-message {
    z-index: 30;
}

.bs-heavy {
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}


.hc-launch {
    position: relative;
    top: 6px;
    color: $tealOriginal;
    cursor: pointer;
}


.text-underline:hover {
    text-decoration: underline;
}

.w-50-lg {
    width: 100%
}

@media(min-width: 1000px) {
    .w-50-lg {
        width: 50%
    }
}


.col-lg-5-across {

    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;

}

@media(min-width: 1000px) {
    .col-lg-5-across {
        flex: 0 0 20%;
        max-width: 20%;
    }
}
