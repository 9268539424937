// fix bug in safari of modals not being in the correct position
.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none;
    transition: none;
}



@if ($theme == dark) {

    .modal-backdrop {
        // opacity: .4 !important;
        // filter: blur(100px);
        background-color: darken($white, 45%);
    }

}

.modal-body,
.modal-header {
    padding: $card-spacer-y $card-spacer-x;
}

.modal-header {
    border-bottom: solid 1px $gray-300;

    .modal-title {
        margin-top: 4px;
    }
}

.modal-footer {
    border-top: solid 1px $gray-300;
}

.modal-content {
    max-height: 90vh;
    overflow: auto;
}

.modal-content {
    border-radius: 0 !important;
    max-width: 100%;

}

.modal-backdrop.show {
    opacity: 0.6;
}

.fade {
    transition: opacity 0.05s linear;
}

.fade.show .modal-dialog {
    animation-duration: 0.4s !important;
}
