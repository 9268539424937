.admin-layout {

    .form-control {

        background-color: rgba(0,0,0,.03);
        // color: black !important

        height: auto;
        padding: 6px 10px

    }

   
}

 .navbar-vertical.navbar-expand-xs.fixed-left {
            background: white !important;
}

.ql-toolbar + .ql-container .ql-editor {
    max-height: 690px;
}

.navbar .navbar-nav .nav-link:hover, .navbar .navbar-nav .nav-link:focus {
    color: $black !important;
}

.navbar .navbar-nav .show > .nav-link, .navbar .navbar-nav .active > .nav-link, .navbar .navbar-nav .nav-link.show, .navbar .navbar-nav .nav-link.active {
    color: #333 !important;
}