// $input-background: $white ;

// .form-control:disabled {
//     background-color: $gray-300 !important;
// }

// select {
//     -webkit-appearance: none !important;
// }
// .form-control:disabled,
// .form-control[readonly] {
//     background-color: $input-bg;
//     opacity: 0.7;
// }
// .form-control:focus {
//     color: #8898aa;
//     background-color: #fff;
//     border-color: #06405C;
//     outline: 0;
//     box-shadow: $box-shadow-sm;
// }
// .rdtPicker .rdtTimeToggle:before {

//     content: "Time Select";
// }

// .form-control {
//     background: $input-background;
//     padding-left: $spacer;
//     padding-right: $spacer;
//     min-height: 38px;
//     border-color: hsl(0,0%,80%);
//     font-size: $font-size-base * .9;
// }

// .form-group {
//     margin-bottom: $spacer;
// }

// .custom-checkbox .custom-control-label {

//     &::before {
//         top: -0px;
//     }

//     &::after {
//         top: -0px;
//     }

// }

// .react-datepicker-wrapper {
//     display: block;

//     .react-datepicker__input-container {
//         position: relative;
//         display: inline-block;
//         width: 100%;

//         input {
//             width: 100%;


//             border-radius: 0;
//             color: $input-color;

//             background: $input-background;
//             padding-left: $spacer;
//             padding-right: $spacer;
//             min-height: 38px;
//             border-color: hsl(0,0%,80%);

//             border: solid 1px $input-border-color;
//             border-width: $input-border-width;
//             box-shadow: $input-box-shadow;
//             &:active {
//                 background: $input-bg;
//             }
//         }

//     }

// }



// .date-picker-wrapper {

//     &.invalid {
//         input {
//             border: solid 1px $red;
//         }
//     }

//     &.valid {
//         input {
//             border: solid 1px $green;
//         }
//     }
// }

// label {
//     margin-bottom: $spacer / 2.5;
// }

// @media(max-width: 576px) {

//     select, input, textarea {
//         font-size: 16px !important;
//     }

//     .form-control  {

//         padding: .2rem .7rem !important;
//         height: calc(2rem + 2px) !important;

//         select, input, textarea {
//             font-size: 16px !important;
//         }
//     }

// }

// @media(max-width: 768px) {
//     .w-50.form-group {
//         width: 100% !important;
//     }
// }

// .react-datepicker-popper {
//     z-index: 99999 !important;
// }


// .bootstrap-tagsinput {


//         width: 100%;


//     input {
//         border: solid 1px $gray-200;
//         margin-top: $spacer;
//         transition: all 0.15s ease-in-out;
//         height: calc(1.875rem + 2px);
//         padding: 0.1875rem 0.3rem;
//         line-height: 1.5;
//         color: #8898aa;
//         border: 1px solid #dee2e6;
//         border-radius: 0;
//         background: $input-background;
//         width: 100%;
//         display: block;

//         &:focus {
//             border: solid 1px $gray-200;
//             background: $white
//         }

//     }

//     .badge.badge-warning {
//         background: $orange;
//     }

//     ul {
//         position: absolute;
//         padding-left: 0;
//         z-index: 1000;
//         width: 100%;
//         box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);

//         li {
//             background-color: $gray-100;
//             list-style: none;
//             padding: $spacer * .5 $spacer;
//             width: 100%;
//             border-bottom: solid 1px $gray-400;
//             cursor: pointer;
//             transition: all .3s;

//             &:hover {
//                 background: $gray-200;
//             }

//         }

//     }

// }

// .quill-invalid {

//     .quill .ql-container .ql-editor {
//         border: solid 1px $red !important;
//         border-color: solid 1px $red !important;
//     }

// }

// .label-block {

//     label {
//         display: block;
//     }

// }


// .react-select {

//     // height: 30px;

//     div {
//         background: $input-background;
//         color: $input-color;
//         font-size: $font-size-base * .9;

//     }


// }

.form-control {

    border-radius: 8px;
    background: $gray-200;
    padding: 20px;

}

select.form-control {

    padding: 10px 15px 8px;
    height: auto;

}

@media(max-width: 600px) {
    .form-control {

        font-size: 16px !important

    }

    select.form-control {

        min-height: 45px;

        font-size: 16px !important

    }
}

.form-group {

    margin-bottom: 5px;

    .form-control-label {
        font-weight: 300;
        color: $text-muted ;
        font-size: .7rem;
        margin-bottom: 0px;
    }

}

