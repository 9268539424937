
// @media(max-width: 768px) {
//     .react-bootstrap-table-pagination-total {
//          display: none;
//     }

//     .dataTables_filter {
//         input, select {
//             width: 100%;
//         }
//     }

//     .dataTables_length {
//         display: none !important
//     }

// }

// .table {

//     // table-layout: fixed;

//     td {
//         vertical-align: middle;
//         word-break: break-all;
//     }

//     .aligned-image {
//         position: relative;
//         margin-top: -0px;

//     }

//     &.table-hover {

//         tr {
//             // transition: all .3s;
//         }

//         tr:hover {
//             background: $white;
//             // box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
//             // border-top: solid 2px $gray-500 !important;
//             // border-bottom: solid 2px $gray-500 !important;
//             background: $gray-300 !important;

//             cursor: pointer;

//             z-index: 1000;

//             td {
//                 position: relative;
//                 // left: -5px;
//             }

//         }

//     }

// }




// @if ($theme == dark) {
//     .page-item.disabled .page-link {

//         background: $gray-900;
//         border-color: $blackOffset;
//     }
// }

// .react-bs-table-csv-btn {
//     background: $primary !important;
//     border-color: $primary !important;
// }

// .page-item.active .page-link {
//     z-index: 1;
//     color: #fff;
//     background-color: $teal;
//     border-color: $teal;
// }

// .table-no-margin {
//     table.table {
//         margin-bottom: 0;
//     }
// }

// .table-responsive {

//     .row {
//         margin-left: 0;
//         margin-right: 0;
//     }

//     .col-md-6:first-child, .col-xs-6:first-child, .col-sm-6:first-child, .col-lg-6:first-child {
//         padding-left: 0;;
//     }

//     .col-md-6:last-child, .col-xs-6:last-child, .col-sm-6:last-child, .col-lg-6:last-child {
//         padding-right: 0;;
//     }

// }

// .table-not-fixed {

//     table.table {
//         table-layout: unset;

//         td {
//             word-break: break-word;
//             white-space: pre;
//         }
//     }

// }

// table.table {

//     td {
//         word-break: break-word;
//         // white-space: pre;
//     }
// }

// .react-bootstrap-table-pagination .dataTables_length select.form-control-sm {
//     width: 40px;
//     text-align: center;
//     margin: auto;
// }


// .table-parent-bordered {

//     .table {

//         td {
//             border: solid 4px $gray-200;
//         }

//         .react-json-view {
//             font-size: 1.1rem;
//         }

//     }

// }


// .archk-table-expansion {

//     position: relative;

//     // &:hover {
//     //     .archk-table-expansion-content {
//     //         display: inline;
//     //     }

//     //     .archk-table-expansion-teaser {
//     //         display: none
//     //     }
//     // }

//     .archk-table-expansion-content {
//         display: none;
//         position: absolute;
//         top: -20px;
//         background-color: $white;
//         padding: $spacer;
//         z-index: 999;
//         box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
//         cursor: default;
//     }

//     .archk-table-expansion-teaser {
//         display: inline;
//     }

// }

// .table-notifications {

//     a:not(.no-highlight) {
//         color: $green;
//         margin-right: 10px;
//         font-weight: bold;
//     }

// }

// .react-bootstrap-table {

//     .table {
//         margin-bottom: $spacer

//     }



// }

// .pagination.react-bootstrap-table-page-btns-ul {

//     margin-bottom: $spacer;
//     padding-right: $spacer !important;

//     li {

//         a {
//             width: 33px;
//             height: 33px;
//         }

//     }

// }

.table {

    td {
        vertical-align: middle;
        white-space: pre-line;
    }

}