@if ($theme == dark) {

    .bg-secondary {
        background: $black !important;
    }

    .bg-white {
        background: $blackOffset !important;
    }

    .fill-default {
        fill: $black;
    }

    .bg-secondary {
        background: $gray-300 !important;
    }



}

.bg-info-dark {
    background: $blue !important;
}
.bg-info-light {
    background: #064463 !important;
}
