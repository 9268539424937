
@keyframes shadow-pulse {
    0% {
         box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);

    }
    100% {

         box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
    }
}


.btn-pulse {
   animation: shadow-pulse 1s infinite;
}

@if ($theme == dark) {

     .btn.btn-neutral {
        background: lighten($black, 5%);
        border-color: lighten($black, 1%);
        color: $white;
     }

 }

.btn {

    padding: 10px 60px;
    font-weight: normal;
     &.btn-primary,&.btn-info {
          background: $teal;

     }

     &.btn-success {
          border-color: darken($green, 5%) !important;
        color: $teal
     }

     &.btn-lg {
         font-size: 1.3rem;
     }

}
