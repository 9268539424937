.badge {

    &.badge-lg {
        // font-size: $font-size-base;
        padding: $spacer;
    }

}



