.card {


}

.hero-card {

    position: relative;
    top: 200px;
    margin-top: -170px;

    .divider {
        align-self: center;
        position: relative;
        top: -30px;

    }

    .line {
        border-right: solid 1px black;
        height: 100%;
        height: 100px;
        display: inline-block;

        &.top {
            position: absolute;
            top: 30px;
            left: calc(50% + 1px);
        }

        &.bottom {
            position: absolute;
            bottom: 30px;

            left: calc(50% + 1px);
        }



    }

}
