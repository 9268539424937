
@if ($theme == dark) {
    .text-dark {
        color: $white !important;
    }

    .bg-white.text-dark, .bg-white .text-dark {
        color: $black !important;
    }

}


.text-gradient-success {
    color: $green;
    background: linear-gradient(to right, darken($green, 20%), $green);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-light {
   color: #3673B2;
}
.text-info {
   color: $teal;
}

.text-gradient-warning {
    color: $green;
    background: linear-gradient(to right, darken($orange, 20%), $orange);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.text-gradient-danger {
    color: $green;
    background: linear-gradient(to right, darken($red, 20%), $red);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-info-original {
    color: $tealOriginal !important;
}

.overflow-text {
    margin-right: -150px;
}

.text-right .overflow-text {
    margin-left: -150px;
    margin-right: inherit
}

.display-2 {

    line-height: 50px;
    font-size: 2.7rem;

}

.lead {
    color: $teal;
    font-size: 1.4rem;
}

.text-subscript {
    font-size: .7rem;;
    font-weight: lighter;
    line-height: 15px
}

p {
    font-family: 'PoppinsMedium' !important;
    line-height: 25px
}

@media(max-width: 768px) {

    .display-2 {
        line-height: 45px;
        font-size: 2.2rem;
    }
}
