.archk-spin {

    -webkit-animation: spin1 2s infinite linear;
    -moz-animation: spin1 2s infinite linear;
    -o-animation: spin1 2s infinite linear;
    -ms-animation: spin1 2s infinite linear;
    animation: spin1 2s infinite linear;

    @-webkit-keyframes spin1 {
    0% { -webkit-transform: rotate(0deg);}
    100% { -webkit-transform: rotate(360deg);}
    }
    @-moz-keyframes spin1 {
    0% { -moz-transform: rotate(0deg);}
    100% { -moz-transform: rotate(360deg);}
    }
    @-o-keyframes spin1 {
    0% { -o-transform: rotate(0deg);}
    100% { -o-transform: rotate(360deg);}
    }
    @-ms-keyframes spin1 {
    0% { -ms-transform: rotate(0deg);}
    100% { -ms-transform: rotate(360deg);}
    }
    @-keyframes spin1 {
    0% { transform: rotate(0deg);}
    100% { transform: rotate(360deg);}
    }

}

.color-circle {

    width: 8px;
    height: 8px;
    border-radius: 100%;
    display: inline-block;
    margin-left: $spacer * .5;
    margin-right: $spacer * .5;

}

.avatar-image {
    display: inline-block;
    width: 30px;
    height: 30px;
    border-radius: 100%;
}

.contact-note {
    a {
        text-decoration: underline;
        font-weight: bold;
    }
}

.z-depth-1 {
    -webkit-box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);
}

.z-depth-2 {
    -webkit-box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
    box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.3);
}
.z-depth-3 {
    -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
    box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
}
.z-depth-4 {
    -webkit-box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
    box-shadow: 0 16px 24px 2px rgba(0,0,0,0.14), 0 6px 30px 5px rgba(0,0,0,0.12), 0 8px 10px -7px rgba(0,0,0,0.2);
}
.z-depth-5 {
    -webkit-box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
    box-shadow: 0 24px 38px 3px rgba(0,0,0,0.14), 0 9px 46px 8px rgba(0,0,0,0.12), 0 11px 15px -7px rgba(0,0,0,0.2);
}

.email-content body {
    background: transparent;
    min-height: 0;
}

.gradient-box {

    display: flex;
    align-items: center;
    //width: 50vw;
    // width: 90%;
    // margin: auto;
    // max-width: 22em;

    position: relative;
    // padding: 30% 2em;
    box-sizing: border-box;
    text-align: center;

    $border: 5px;
    color: #FFF;
    background: white;
    background-clip: padding-box; /* !importanté */
    border: solid $border transparent; /* !importanté */
    border-radius: 2em;

    &:before {
      content: '';
      position: absolute;
      top: 0; right: 0; bottom: 0; left: 0;
      z-index: -1;
      margin: -$border; /* !importanté */
      border-radius: inherit; /* !importanté */
      background: linear-gradient(to right, $green, $tealOriginal);
    }

    h2 {
        width: 100%;
    }

  }


// .navbar-add {

//     display: flex;
//     align-items: center;
//     //width: 50vw;
//     // width: 90%;
//     // margin: auto;
//     // max-width: 22em;

//     position: relative;
//     // padding: 30% 2em;
//     box-sizing: border-box;
//     text-align: center;

//     $border: 5px;
//     // color: #FFF;
//     // background: white;
//     background-clip: padding-box; /* !importanté */
//     // border: solid $border transparent; /* !importanté */
//     border-radius: 2em;

//     &:before {
//       content: '';
//       position: absolute;
//       top: 0; right: 0; bottom: 0; left: 0;
//       z-index: -1;
//       margin: -$border; /* !importanté */
//       border-radius: inherit; /* !importanté */
//       background: linear-gradient(to right, $green, $tealOriginal);
//     }

//     h2 {
//         width: 100%;
//     }

//   }

.offices {

    h2 {

        position: relative;

        hr {
            border-top: solid 3px black;
            position: absolute;
            right: 0;
            width: calc(1005 - 275px);
            top: 50%;
            margin: 0;
            left: 275px;
        }

    }

}

.border-bottom-dashed {
    border-bottom: dashed 2px #eee
}

.step-process {



    .step {
        border-bottom: dashed 2px #eee
    }

}

.hero {

    .bg-img {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        max-height: 175px;
        width: 100%;
        opacity: .5;
    }

    .floating-mike {
        z-index: 2;
        position: absolute;
        right: 0;
        bottom: 0;
    }

    @media(max-width: 1000px) {
        .floating-mike {
            display: none;
        }
    }
}

.page-content {

    .container {
        padding-left: 88px;
        padding-right: 88px;

    }

    @media(max-width: 1100px) {

        .container {
            padding-left: 44px;
            padding-right: 44px;

        }

    }

    @media(max-width: 400px) {

        .container {
            padding-left: 15px;
            padding-right: 15px;

        }

    }

}



.container {
    width: 1440px;
    max-width: 95%;
}

.pagination {

    .page-link {
        width: 25px !important;
        height: 25px !important;
    }

    .page-item {


        &:first-child {
            margin-right: 20px;
        }

        &.active {

            a {
                background: none !important;
                color: inherit;
                box-shadow: none;
                border: solid 1px $gray-700;
                border-radius: 0 !important ;
            }

        }

    }

    a {

        padding: 0;
        border: none;
        background: none;
        border-radius: 0;

    }

}

.contact-hero-container {
    position: relative;
    top: 250px;
}

@media(max-width: 1280px) {

    .contact-hero-container {
        top: 150px;
    }

    .contact-hero-filter {
        background: rgba(0,0,0,.5)
    }


}

@media(max-width: 1100px) {

    .contact-hero-container {
        top: 50px;
    }

    .contact-hero-filter {
        background: rgba(0,0,0,.5)
    }


}

@media(max-width: 1000px) {

    .home-hero-container {
        max-width: 100%;
        padding-left: 0;
        padding-right: 0;

        .px-8 {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

}

.found-featured-container {
    width: 800px;
    max-width: 90%;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.blog-article {


    .article-hero {

        background-position: center;
        background-size: cover;

        .filter {

            padding: 150px 0;
            background: rgba(0,0,0,.6);

            h1 {
                color: $white;
                font-size: 3.3rem;
                line-height: 1.3;
                max-width: 800px;
            }

        }

    }

      @media(min-height: 1000px) {

          .article-hero .filter {
              padding: 200px 0;
          }

    }

    @media(min-height: 1100px) {

          .article-hero .filter {
              padding: 250px 0;
          }

    }

    .sidebar {
        padding-left: 30px;
    }

    @media(max-width: 768px) {

        .sidebar, .article {
            padding-left: 15px !important;
            padding-right: 15px !important;
        }
    }

    .article {

        padding-right: 30px;
        a {
            color: $green;
        }

        iframe {
            border-radius: 6px;
            -webkit-box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
            box-shadow: 0 8px 17px 2px rgba(0,0,0,0.14), 0 3px 14px 2px rgba(0,0,0,0.12), 0 5px 5px -3px rgba(0,0,0,0.2);
        }

        h1, h2, h3, h4 {

            margin-top: 2rem;
            font-size: 2rem;

        }

    }
}

@media(max-width: 600px) {
    .drop-us-a-line {
        font-size: 25px
    }
}


@media(max-width: 1280px) {

    .contact-hero {

        margin-bottom: -100px;

    }

}

@media(max-width: 1080px) {

    .contact-hero {

        margin-bottom: -200px;

    }

}


@media(max-width: 1000px) {

    .contact-hero {

        margin-bottom: -150px;

        .divider {
            display: none;
        }

       .container {
            max-width: 100%;
            width: 100%;
            padding: 0
       }


    }

}

.debt-restructuring {


    .debt-restructuring-hero {

        .card {
            margin-bottom: -400px;
        }

        .filter {
            background: rgba(0,0,0,.6)
        }

        .display-4 {
            font-size: 1.4rem;
        }

    }

    @media(max-width: 1000px) {

        .under-hero {
            margin-top: 200px;
        }

        .debt-restructuring-hero {

            .container {
                max-width: 100%;
                padding-left: 0;
                padding-right: 0;
            }



        }

    }

    @media(max-width: 768px) {

        .under-hero {
            margin-top: 300px;
        }


    }


}


.video-container {
    overflow: hidden;
    position: relative;
    width:100%;
}

.video-container::after {
    padding-top: 56.25%;
    display: block;
    content: '';
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media(max-width: 600px) {
    .review {
        button {
            display: block;
            margin-bottom: 20px;
            width: 100%;
        }
    }
}