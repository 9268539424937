
.navbar {

    .navbar-nav {



        .nav-item {

            &.show {

                a {
                    color: $teal ;
                }

                .nav-link {
                    color: $teal !important;
                }

            }

            .dropdown-menu.dropdown-menu-right.show button{
                color: $teal ;
            }

        }

        .nav-link {

            &.active {
                color: $green !important;
                text-decoration: underline
            }

        }

    }

}

@media(max-width: 1100px) {

    .header {
        .navbar {

           .collapse {
            .btn.btn-primary {
                padding: 15px  20px;
            }
           }

        }
    }


}
