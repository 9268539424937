.header {

    .container {
        display: flex;
    }

    .navbar {

        padding: 0;

        li {
            font-size: 0.9rem;
            font-family: 'PoppinsSemiBold' !important;
        }

        .btn.btn-success {
            padding: 20px 40px;
            position: relative;
            top: -6px;
        }

        .nav-item {
            a {
                // padding-top: $spacer * 3;
                // padding-bottom: $spacer * 3;
                color: $teal !important;
            }
        }

        .facebook {
            padding-right: 30px;
            font-size: 1.5rem;
        }

        .google-stars {

            line-height: 18px;
            padding: 8px;
            background: white;
            font-size: 0.7rem;
            border-top: solid 1px #eee;
            border-bottom: solid 1px #eee;

            i {
                &:first-child {
                    margin-left: 4px;
                }
                font-weight: light;
            }

            .line {
                display: block;
            }
        }
        @media(max-width: 1000px) {
            .google-stars {
                border: none;
                background: transparent;
                margin-right: -20px;

                .number {
                    color: $orange;
                    margin-right: 5px;
                    font-weight: bold;
                }

                .text-muted {
                    color: $primary !important;
                }
            }
        }

    }
    @media(max-width: 1000px) {



        .navbar-collapse {
            transition: 0s;
            margin-top: 25px;
            background: $gray-300;
            padding: $spacer;

            li {
                padding: 10px 0;
            }
        }

        .collapsing {
            transition: 0s;
            display: none;
        }


        .nav-top {
            flex-direction: row;

            li {

                position: relative;
                top: 4.5px;
                &:first-child {
                    margin-right: 15px;
                }

            }
        }

        .nav-banner {
            background: #CB0100;

            &.container {
                max-width: 100%;
            }

            .text-danger {
                color: $primary !important;
                font-weight: bold;
            }

            .text-info-light {
                color: $white !important;
            }

        }

    }
    @media(max-width: 400px) {
        .google-stars {
            margin-right: -5px !important;
        }

        .nav-banner {
            p {
                position: relative;
                left: -3px;
                // font-size: .7rem !important;
                line-height: 20px;
                text-align: left !important;

            }
        }

    }

    .speak-to-an-attorney {

        .btn {
            width: 100%;
            font-size: 1.2rem;
            margin: 0;
            border-bottom: solid 1px white;
            padding-top: 20px;;
            padding-bottom: 20px;;
        }

    }

}
